<template>
<div class="d-flex">
    <div class="tw-flex-1 ku-left-box">
        <!-- search bar -->
        <div class="d-flex pr-0 sm:pr-3">
            <svg-icon name="arrow-back" original width="21" class="tw-cursor-pointer" @click="goBack()"></svg-icon>
            <div class="mx-4 border-right divider-color"></div>
            <div class="tw-flex-1 d-flex ku-search-box-51">
                <div class="d-flex px-3 bg-dark-500 h-100 align-items-center tw-cursor-pointer ku-search-type">
                    <svg-icon name="camera" original width="20"></svg-icon>
                    <span class="pl-2 text-dark-100 font-15">Fotos</span>
                    <div class="ml-2">
                        <svg-icon name="arrow-down" width="10" original></svg-icon>
                    </div>
                </div>

                <input type="text" placeholder="Homebanking" v-model="query.keyword" @keyup.enter="hFilterKeyword">
                <b-button variant="danger" @click="hFilterKeyword" width="60px">
                    <svg-icon name="search" color="white" width="26"></svg-icon>
                </b-button>
            </div>
        </div>

        <!-- title -->
        <div class="mt-4">
            <h3 class="font-21">Homebanking Fotos</h3>
            <p class="font-16 text-dark-100 mb-3">{{total_count}} home banking Stock-Fotos und Fotografie sind lizenzfrei verfügbar.</p>
        </div>

        <!-- Image gallery -->
        <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-4">
            <div class="bg-white text-gray-900 ku-image-box" v-for="item in items" :key="item.id">
                <img :src="item.assets.huge_thumb.url" alt="" class="mx-auto tw-object-contain">
                <div class="ku-btn-upload bg-primary tw-rounded-full" v-if="item.id.includes(8)">
                    <svg-icon name="checked" color="white" width="20"></svg-icon>
                </div>
                <div class="ku-btn-upload white tw-rounded-full" v-else>
                    <svg-icon name="plus" color="#08ACC0" width="20"></svg-icon>
                </div>
            </div>
        </div>

        <!-- Load More -->
        <div class="text-center mt-4">
            <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                <b-button ref="button" class="px-4" :disabled="isLoading || total_count <= items.length" variant="primary" @click="getList">
                    Weiter
                </b-button>
            </b-overlay>
        </div>
    </div>

    <!-- Rright -->
    <div class="ku-right-box">
        <!-- Right-1 -->
        <div>
            <p class="font-21 tw-font-bold mb-2">Auftrag</p>
            <div class="p-2">
                <div class="">
                    <img src="/upload/files/mask.png" alt="" class="tw-w-full tw-rounded-t-xl">
                    <p class="mt-2">Homebanking</p>
                    <p>Fällig bis: 26.05.2020</p>

                    <p class="mt-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                    <p class="text-primary">Mehr anzeigen</p>

                    <div class="d-flex mt-4">
                        <div class="pr-3">
                            <img src="/upload/user2.jpg" alt="" class="tw-rounded-full" width="40" height="40">
                        </div>
                        <div>
                            <p class="tw-font-bold">Max Mustermann</p>
                            <p>Redakteur</p>
                        </div>
                    </div>
                    <!-- button -->
                    <div class="mt-4">
                        <button type="button" class="btn btn-outline-primary w-100" style="height: 52px">Frage stellen</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Right-2 -->
        <div class="mt-4">
            <p class="font-21 tw-font-bold mb-2">Ausrichtung</p>
            <div class="tw-grid tw-grid-cols-2 tw-gap-2 pr-2">
                <div>
                    <button type="button" class="btn btn-outline-primary w-100 font-13">Alle Ausrichtungen</button>
                </div>
                <div>
                    <button type="button" class="btn btn-outline-primary w-100 font-13">Horizontal</button>
                </div>
                <div>
                    <button type="button" class="btn btn-outline-primary w-100 font-13">Vertikal</button>
                </div>
            </div>
        </div>
        <!-- Right-3 -->
        <div class="mt-4">
            <p class="font-21 tw-font-bold mb-2">Nutzung</p>
            <div class="tw-grid tw-grid-cols-2 tw-gap-2 pr-2">
                <div>
                    <button type="button" class="btn btn-outline-primary w-100 font-13">Nur redaktionell</button>
                </div>
                <div>
                    <button type="button" class="btn btn-outline-primary w-100 font-13">Nicht-redaktionell</button>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: "Tasks",
    data() {
        return {
            isLoading: false,
            query: {
                keyword: this.$route.query.keyword || '',
                page: 1,
                per_page: 12,
                color: '',
                people_age: '',
                people_gender: '',
                orientation: '',
            },
            items: [],
            total_count: 0
        }
    },
    methods: {
        goBack() {
            this.$router.back()
        },
        getList() {
            this.isLoading = true
            this.$axios.post('/api/images', this.query).then(({
                data
            }) => {
                this.total_count = data.data.total_count
                this.items.push(...data.data.data)
                this.query.page++;
            }).finally(() => {
                this.isLoading = false
            })
        },
        hFilter() {
            this.query.page = 1;
            this.items = [];
            this.total_count = 0;
            this.getList()
        },
        hSel(kk, fcolor) {
            this.query[kk] = fcolor
            this.hFilter()
        },
        hFilterKeyword() {
            if (this.$route.query.keyword != this.query.keyword) {
                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        keyword: this.query.keyword
                    }
                })
                this.hFilter()
            }
        },
        hAddCloud(item) {
            console.log(item);
        }
    },
    created() {
        this.getList()
    }
}
</script>
